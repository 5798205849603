<template>
	<div>
		<div class="flex justify-between items-end">
			<div>
				<img :src="a.logo" v-if="a.is_auth" class="max-w-xs" />
				<div class="pt-3">
					<h3 class="font-bold">{{ a.companyname }}</h3>
					{{ a.zip }} {{ a.city }} &middot; {{ a.street }}
				</div>
			</div>
			<div class="authContainer pb-1.5">
				<div
					v-for="(industry_auth, index) in a.industries_auth"
					:key="index"
					:class="industry_auth"
				>
					<img
						v-if="industry_auth === 'building'"
						:src="this.getImage('assets/images/Hochbau.png')"
					/>
					<img
						v-if="industry_auth === 'survey'"
						:src="this.getImage('assets/images/Vermessung.png')"
					/>
					<img
						v-if="industry_auth === 'heavyconstruction'"
						:src="this.getImage('assets/images/Tiefbau.png')"
					/>
					<span :class="industry_auth" class="bigCircle"
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="15.569"
							height="11.933"
							viewBox="0 0 15.569 11.933"
						>
							<path
								d="M16.5-10.426a.93.93,0,0,1,.281.683.93.93,0,0,1-.281.683L9.231-1.788,7.865-.422a.93.93,0,0,1-.683.281A.93.93,0,0,1,6.5-.422L5.133-1.788,1.5-5.424a.93.93,0,0,1-.281-.683A.93.93,0,0,1,1.5-6.79L2.863-8.156a.93.93,0,0,1,.683-.281.93.93,0,0,1,.683.281L7.182-5.193l6.589-6.6a.93.93,0,0,1,.683-.281.93.93,0,0,1,.683.281Z"
								transform="translate(-1.215 12.074)"
								fill="#fff"
							/></svg
					></span>
				</div>
			</div>
		</div>
		<div class="flex pt-3 flex-col">
			<div class="solutionList inlineItems">
				<span
					v-for="(solution, index) in a.solutions"
					:key="index"
					class="solutionListItem"
					v-html="globals['solutions'][solution]"
				></span>
			</div>
			<a
				:href="'#p=' + a.partnerID + '&a=' + a.id"
				class="detailLink icon-angle-right"
				@click="triggerOverlay(a.partnerID, a.id)"
				>Details anzeigen</a
			>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			frontendBase: process.env.VUE_APP_FRONTEND_URL,
		};
	},
	props: {
		a: Object,
		globals: Object,
	},
	methods: {
		triggerOverlay(partnerId, addressId) {
			this.$emit("open-overlay", {
				partnerId: partnerId,
				addressId: addressId,
			});
		},
		getImage(imageName) {
			return this.frontendBase + imageName;
		},
	},
};
</script>

<style>
</style>