<template>
	<div class="flex flex-wrap">
		<p class="w-full leading-6">
			<strong>{{ validAddressData.name }}</strong
			><br />
			{{ validAddressData.street }}<br />
			{{ validAddressData.zip }} {{ validAddressData.city }}
		</p>
		<p class="md:w-1/2 mt-2 leading-6 flex">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="18.064"
				height="20.668"
				viewBox="0 0 18.064 20.668"
				class="mr-2"
			>
				<path
					d="M6.763,13.92,3.968,17.458l2.214,1.776a1.817,1.817,0,0,0,2.542-.27,50.615,50.615,0,0,0,4.665-6.756,50.881,50.881,0,0,0,3.521-7.421,1.82,1.82,0,0,0-1.037-2.335L13.228,1.42,11.56,5.613l1.488.58a.516.516,0,0,1,.3.669l-.017.039C12.7,8.266,12,9.628,11.23,10.959s-1.6,2.628-2.472,3.856a.518.518,0,0,1-.722.12Zm.85-13.9a.518.518,0,1,1,.318.987A9.943,9.943,0,0,0,1.412,13.183a.519.519,0,0,1-1,.279,10.955,10.955,0,0,1,.91-8.2A10.912,10.912,0,0,1,3.959,2.037,11.04,11.04,0,0,1,7.613.024m.539,3.069a.517.517,0,1,1,.368.965A6.88,6.88,0,0,0,4.3,12.081a.517.517,0,0,1-1.006.241A7.933,7.933,0,0,1,3.14,9.45,7.886,7.886,0,0,1,8.152,3.093M2.84,17.222l3.437-4.359a.519.519,0,0,1,.729-.077l1.223.98c.739-1.064,1.447-2.181,2.106-3.324s1.271-2.308,1.824-3.485L10.7,6.386a.521.521,0,0,1-.291-.672L12.455.566a.518.518,0,0,1,.669-.3l3.122,1.218a2.855,2.855,0,0,1,1.634,3.663,51.818,51.818,0,0,1-3.594,7.572,51.834,51.834,0,0,1-4.761,6.9,2.852,2.852,0,0,1-3.991.419L2.921,17.947a.518.518,0,0,1-.082-.724"
					transform="translate(0 0)"
					fill="#333"
					fill-rule="evenodd"
				/></svg
			>{{ validAddressData.phone }}
		</p>
		<p class="md:w-1/2 mt-2 leading-6 flex">
			<a :href="`mailto:${validAddressData.email}`" class="mr-10 flex">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					xmlns:xlink="http://www.w3.org/1999/xlink"
					width="21.333"
					height="14.101"
					viewBox="0 0 21.333 14.101"
					class="mr-2"
				>
					<g id="Gruppe_418" data-name="Gruppe 418" transform="translate(0 0)">
						<path
							d="M21.326,12.4a.5.5,0,0,1-.042.274,1.859,1.859,0,0,1-1.8,1.426H1.849a1.853,1.853,0,0,1-1.8-1.426A.482.482,0,0,1,0,12.4c0-.049,0-.1,0-.15V1.849A1.846,1.846,0,0,1,.512.573l.03-.03A1.845,1.845,0,0,1,1.849,0H19.484a1.832,1.832,0,0,1,1.3.542l0,0a1.836,1.836,0,0,1,.545,1.3v10.4c0,.051,0,.1-.007.15M1.008,2.7,7.092,7.3l.016.014,3.051,2.3a.842.842,0,0,0,.507.173.852.852,0,0,0,.507-.173l3.048-2.3.019-.014,6.083-4.6V1.849a.833.833,0,0,0-.245-.594l0,0a.828.828,0,0,0-.591-.245H1.849a.84.84,0,0,0-.594.248l-.021.019a.836.836,0,0,0-.227.575ZM20.323,3.962,15.37,7.707l4.953,3.745ZM14.535,8.338l-2.756,2.083a1.828,1.828,0,0,1-2.228,0L6.8,8.338,1.1,12.642a.811.811,0,0,0,.152.2.84.84,0,0,0,.594.248H19.484a.828.828,0,0,0,.591-.245v0a.811.811,0,0,0,.152-.2ZM5.961,7.707,1.008,3.962v7.49Z"
							transform="translate(0 -0.001)"
							fill="#2c3e50"
							fill-rule="evenodd"
						/>
					</g>
				</svg>
				E-Mail</a
			>
			<a :href="validAddressData.www" target="_blank" class="flex"
				><svg
					xmlns="http://www.w3.org/2000/svg"
					width="21.657"
					height="21.659"
					viewBox="0 0 21.657 21.659"
					class="mr-2 -mt-0.5"
				>
					<g id="Gruppe_477" data-name="Gruppe 477" transform="translate(0 0)">
						<path
							d="M8.675,1.328A9.755,9.755,0,0,0,1.981,6.752H6.05a13.8,13.8,0,0,1,.9-2.835,8.679,8.679,0,0,1,1.724-2.59m4.306,0a8.631,8.631,0,0,1,1.724,2.59,13.8,13.8,0,0,1,.9,2.835h4.069a9.755,9.755,0,0,0-6.694-5.424M15.8,7.84a21.345,21.345,0,0,1,.2,2.989,21.318,21.318,0,0,1-.2,2.989h4.3a9.8,9.8,0,0,0,0-5.977Zm-.192,7.067a13.8,13.8,0,0,1-.9,2.835,8.7,8.7,0,0,1-1.724,2.59,9.755,9.755,0,0,0,6.694-5.424Zm-4.778,5.624A6.038,6.038,0,0,1,7.941,17.3a12.5,12.5,0,0,1-.777-2.388h7.33a12.5,12.5,0,0,1-.777,2.388,6.018,6.018,0,0,1-2.888,3.236M14.7,13.817a19.994,19.994,0,0,0,.215-2.989A20.019,20.019,0,0,0,14.7,7.84H6.959a20.019,20.019,0,0,0-.215,2.989,19.994,19.994,0,0,0,.215,2.989Zm-.2-7.065H7.163a12.443,12.443,0,0,1,.78-2.388,6.025,6.025,0,0,1,2.885-3.236,6.021,6.021,0,0,1,2.888,3.233,12.531,12.531,0,0,1,.777,2.39M8.675,20.332a8.744,8.744,0,0,1-1.724-2.59,13.849,13.849,0,0,1-.9-2.835H1.981a9.755,9.755,0,0,0,6.694,5.424M5.861,13.817a21.679,21.679,0,0,1,0-5.977H1.555a9.8,9.8,0,0,0,0,5.977Zm15.147.714L21,14.567A10.832,10.832,0,0,1,.661,14.561l-.005-.01v0l-.005-.013A10.832,10.832,0,0,1,21,7.1l0,.01,0,0,0,.013v0a10.88,10.88,0,0,1,0,7.408"
							transform="translate(0 -0.001)"
							fill="#333"
							fill-rule="evenodd"
						/>
					</g></svg
				>Website
			</a>
		</p>
	</div>
</template>

<script>
export default {
	props: {
		addressData: {
			type: Object,
		},
	},
	computed: {
		validAddressData() {
			let data = "";
			if (this.addressData !== undefined) {
				data = this.addressData;
			}
			return data;
		},
	},
};
</script>

<style>
</style>