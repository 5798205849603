<template>
	<div class="filterContainer">
		<div class="industries">
			<h3>Industrie</h3>
			<ul class="checkList" id="industries">
				<li
					v-for="(industry, index) in globals.industries"
					:key="index"
					class="w-1/2"
				>
					<a
						href="#"
						@click.prevent="filterData($event)"
						class="active"
						:id="index"
						:class="industry.class"
						>{{ industry.value }}</a
					>
				</li>
			</ul>
		</div>
		<div class="solutions">
			<h3>
				Lösungen<a
					href="#"
					v-if="this.filterAllActive"
					class="cancelAll"
					@click.prevent="deactiveAll('solutions')"
					>alle deaktivieren<span
						><svg
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="12.936"
							height="12.535"
							viewBox="0 0 12.936 12.535"
						>
							<g transform="translate(0 0)" clip-path="url(#clip-path)">
								<path
									d="M7.876,6.266l4.937-4.935a.407.407,0,0,0,.126-.291.4.4,0,0,0-.126-.287L12.184.126A.4.4,0,0,0,11.9,0a.39.39,0,0,0-.289.126L6.469,5.261,1.332.126A.39.39,0,0,0,1.043,0,.4.4,0,0,0,.754.126L.126.754A.4.4,0,0,0,0,1.041a.407.407,0,0,0,.126.291L5.062,6.266.126,11.2a.395.395,0,0,0,0,.578l.628.628a.4.4,0,0,0,.289.126.4.4,0,0,0,.289-.126L6.469,7.272l5.137,5.137a.4.4,0,0,0,.289.126.4.4,0,0,0,.289-.126l.628-.628a.395.395,0,0,0,0-.578Z"
									transform="translate(0 0)"
									fill="#161615"
								/>
							</g></svg></span
				></a>
				<a
					href="#"
					v-if="!this.filterAllActive"
					class="cancelAll"
					@click.prevent="activeAll('solutions')"
					>alle aktivieren<span
						><svg
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="12.936"
							height="12.535"
							viewBox="0 0 12.936 12.535"
						>
							<g transform="translate(0 0)" clip-path="url(#clip-path)">
								<path
									d="M7.876,6.266l4.937-4.935a.407.407,0,0,0,.126-.291.4.4,0,0,0-.126-.287L12.184.126A.4.4,0,0,0,11.9,0a.39.39,0,0,0-.289.126L6.469,5.261,1.332.126A.39.39,0,0,0,1.043,0,.4.4,0,0,0,.754.126L.126.754A.4.4,0,0,0,0,1.041a.407.407,0,0,0,.126.291L5.062,6.266.126,11.2a.395.395,0,0,0,0,.578l.628.628a.4.4,0,0,0,.289.126.4.4,0,0,0,.289-.126L6.469,7.272l5.137,5.137a.4.4,0,0,0,.289.126.4.4,0,0,0,.289-.126l.628-.628a.395.395,0,0,0,0-.578Z"
									transform="translate(0 0)"
									fill="#161615"
								/>
							</g></svg></span
				></a>
			</h3>
			<ul class="checkList" id="solutions">
				<li
					v-for="(solution, index) in globals.solutions"
					:key="index"
					:class="index === 'werkstaetten' ? 'w-full' : 'w-1/2'"
				>
					<a
						href="#"
						@click.prevent="filterData($event)"
						class="active"
						:id="index"
						v-html="solution"
					></a>
				</li>
			</ul>
			<h3>Performance Partner</h3>
			<ul class="checkList" id="performancePartner">
				<li>
					<a href="#" @click.prevent="filterData($event)" class="blueItem"
						>anzeigen</a
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: ["items", "globals"],
	data() {
		return {
			filterAllActive: true,
		};
	},
	methods: {
		filterData(_event) {
			if (_event) {
				_event.target.classList.toggle("active");
			}
			let selectedIndustries = [];
			let selectedSolutions = [];
			let selected = [];
			const allActiveIndustries = document.querySelectorAll(
				".checkList#industries li a.active"
			);
			allActiveIndustries.forEach((ActiveListElements) => {
				selectedIndustries.push(ActiveListElements.id);
			});

			const allActiveSolutions = document.querySelectorAll(
				".checkList#solutions li a.active"
			);
			allActiveSolutions.forEach((ActiveListElements) => {
				selectedSolutions.push(ActiveListElements.id);
			});

			const performancePartner =
				document.querySelector(".checkList#performancePartner li a.active") ===
				null
					? false
					: true;

			selected["industries"] = selectedIndustries;
			selected["solutions"] = selectedSolutions;
			selected["performancePartner"] = performancePartner;
			this.$emit("filter-items", selected);
		},
		deactiveAll(_list) {
			const allListElements = document.querySelectorAll("#" + _list + " li a");
			allListElements.forEach((listElements) => {
				listElements.classList.remove("active");
			});
			this.filterAllActive = false;
			this.filterData(false);
		},
		activeAll(_list) {
			const allListElements = document.querySelectorAll("#" + _list + " li a");
			allListElements.forEach((listElements) => {
				listElements.classList.add("active");
			});
			this.filterAllActive = true;
			this.filterData(false);
		},
	},
};
</script>

<style>
</style>