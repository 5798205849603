<template>
	<div class="partnerSearch">
		<div class="searchContainer" v-show="view === 'map' || view === 'list'">
			<header
				:style="{
					backgroundImage:
						'url(' + this.getImage('assets/images/bg-header.png') + ')',
				}"
			>
				<div class="flex justify-between items-end flex-col md:flex-row">
					<h2 class="pt-10 pb-2 md:pb-0">
						Finden Sie Ihren Leica Partner in der Nähe
					</h2>
					<div class="buttonWrapper country mb-2">
						<button
							@click="(this.country = 'de'), this.getData()"
							:class="this.country === 'de' ? 'active' : ''"
							class="switchButton"
						>
							DE
						</button>
						<button
							@click="(this.country = 'at'), this.getData()"
							:class="this.country === 'at' ? 'active' : ''"
							class="switchButton"
						>
							AT
						</button>
						<!-- <button
							@click="(this.country = 'ch'), this.getData()"
							:class="this.country === 'ch' ? 'active' : ''"
							class="switchButton"
						>
							CH
						</button> -->
					</div>
				</div>
				<div class="flex justify-between items-center">
					<h3>
						<img
							:src="this.getImage('assets/images/auswahl.svg')"
							class="inline-block"
						/>Auswahl
					</h3>
					<div class="buttonWrapper">
						<button
							@click="this.view = 'map'"
							:class="this.view === 'map' ? 'active' : ''"
							class="switchButton withIcon"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								width="25.333"
								height="22.176"
								viewBox="0 0 25.333 22.176"
							>
								<path
									d="M24.988.142A.774.774,0,0,1,25.333.8V18.213a.773.773,0,0,1-.138.448.749.749,0,0,1-.36.282l-7.915,3.168a.673.673,0,0,1-.593,0L8.709,19.07,1.09,22.111a.654.654,0,0,1-.3.06.8.8,0,0,1-.448-.134A.774.774,0,0,1,0,21.38V3.966a.754.754,0,0,1,.138-.444.743.743,0,0,1,.356-.286L8.409.068a.68.68,0,0,1,.6,0l7.619,3.044L24.243.068a.759.759,0,0,1,.744.074M1.584,4.5V20.206l6.73-2.684V1.814ZM9.1,1.814V17.521l7.125,2.847V4.657ZM23.749,17.684V1.973l-6.73,2.684V20.368Z"
									transform="translate(0 -0.003)"
									fill="#fff"
								/>
							</svg>
							Karte
						</button>
						<button
							@click="this.view = 'list'"
							:class="this.view === 'list' ? 'active' : ''"
							class="switchButton withIcon"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="17"
								viewBox="0 0 20 17"
							>
								<g transform="translate(-1233 -1187)">
									<rect
										width="2"
										height="2"
										transform="translate(1233 1187)"
										fill="#333"
									/>
									<rect
										width="2"
										height="2"
										transform="translate(1233 1192)"
										fill="#333"
									/>
									<rect
										width="2"
										height="2"
										transform="translate(1233 1197)"
										fill="#333"
									/>
									<rect
										width="2"
										height="2"
										transform="translate(1233 1202)"
										fill="#333"
									/>
									<rect
										width="16"
										height="2"
										transform="translate(1237 1187)"
										fill="#333"
									/>
									<rect
										width="16"
										height="2"
										transform="translate(1237 1192)"
										fill="#333"
									/>
									<rect
										width="16"
										height="2"
										transform="translate(1237 1197)"
										fill="#333"
									/>
									<rect
										width="16"
										height="2"
										transform="translate(1237 1202)"
										fill="#333"
									/>
								</g>
							</svg>
							Liste
						</button>
					</div>
				</div>
			</header>
			<section class="flex flex-wrap md:flex-nowrap items-start relative">
				<button
					@click="this.filterOpen = !this.filterOpen"
					:class="this.filterOpen ? 'btnFilterOpen' : 'btnFilterClosed'"
					class="absolute md:hidden z-50"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7.145"
						height="12.252"
						viewBox="0 0 7.145 12.252"
					>
						<path
							d="M7.182-8.646A.388.388,0,0,0,7.3-8.929a.388.388,0,0,0-.123-.282L1.461-14.932a.388.388,0,0,0-.282-.123.388.388,0,0,0-.282.123l-.614.614a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L5.107-8.929.282-4.1a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L.9-2.925a.388.388,0,0,0,.282.123.388.388,0,0,0,.282-.123Z"
							transform="translate(7.305 -2.802) rotate(180)"
							fill="#fff"
						/>
					</svg>
				</button>
				<filter-view
					:items="filteredAdresses"
					:globals="globalSettings"
					@filter-items="filterItems"
					class="filter w-full md:w-1/3 md:mr-2.5"
					v-if="this.filterOpen"
				></filter-view>
				<div class="cotent w-full md:w-2/3 md:ml-2.5 mt-7 md:mt-0">
					<map-view
						v-if="view === 'map'"
						:items="filteredAdresses"
						:countryView="country"
						@open-overlay="openOverlay"
					></map-view>
					<list-view
						v-if="view === 'list'"
						:items="filteredAdresses"
						:countryView="country"
						:globals="globalSettings"
						@open-overlay="openOverlay"
					></list-view>
				</div>
			</section>
		</div>
		<detailView
			v-show="view === 'detail' || specialView === 'detailOverlay'"
			class="detailView"
			:class="specialView"
			:partnerData="currentDetailPartner"
			:detailPartnerAddresses="currentDetailPartnerAddresses"
			:currentPartnerAddressID="currentPartnerAddressID"
			:globals="globalSettings"
			@go-back="goBack"
		></detailView>
	</div>
</template>

<script>
import mapView from "./components/mapView.vue";
import filterView from "./components/filter.vue";
import listView from "./components/listView.vue";
import detailView from "./components/detailView.vue";
import axios from "axios";

export default {
	name: "App",
	components: {
		mapView,
		filterView,
		listView,
		detailView,
	},
	data() {
		return {
			partnerdata: [],
			filteredAdresses: [],
			globalSettings: [],
			view: undefined,
			country: "de",
			currentDetailPartner: [],
			currentDetailPartnerAddresses: [],
			currentPartnerAddressID: undefined,
			currentPartner: false,
			specialView: false,
			frontendBase: process.env.VUE_APP_FRONTEND_URL,
			filterOpen: true,
		};
	},
	mounted() {
		this.getGlobalSettings();
	},
	methods: {
		getImage(imageName) {
			return this.frontendBase + imageName;
		},
		filterItems(activeElements) {
			this.filterData(activeElements);
		},
		filterData(filterItems) {
			let filteredItems = [];
			this.partnerdata.addresses.forEach((addressElement) => {
				let foundIndustry = false;
				let foundSolution = false;
				let foundPerformancePartner = false;
				if (
					filterItems.industries !== "all" &&
					addressElement.is_auth == true
				) {
					filterItems.industries.forEach((industrie) => {
						if (addressElement.industries.includes(industrie)) {
							foundIndustry = true;
						}
					});
				} else {
					foundIndustry = true;
				}
				if (filterItems.solutions !== "all") {
					filterItems.solutions.forEach((solution) => {
						if (addressElement.solutions.includes(solution)) {
							foundSolution = true;
						}
					});
				} else {
					foundSolution = true;
				}
				if (filterItems.performancePartner == true) {
					foundPerformancePartner = true;
				} else if (addressElement.is_auth == true) {
					foundPerformancePartner = true;
				}

				if (foundIndustry && foundSolution && foundPerformancePartner) {
					filteredItems.push(addressElement);
				}
			});
			this.filteredAdresses = filteredItems;
		},
		bounceMarker(id) {
			const current = this.items.find((item) => item.id === id);
			current.active = !current.active;
		},
		getData() {
			axios
				.get(
					process.env.VUE_APP_BACKEND_URL + "get/partnerData/" + this.country
				)
				.then((response) => {
					this.partnerdata = response.data;
					this.filteredAdresses = response.data.addresses;
					this.filterData({
						industries: "all",
						solutions: "all",
						performancePartner: false,
					});
					this.setView();
				});
		},
		getGlobalSettings() {
			axios
				.get(process.env.VUE_APP_BACKEND_URL + "get/globalSettings")
				.then((response) => {
					this.globalSettings = response.data;
					this.getData();
				});
		},
		setView() {
			if (window.location.hash !== "") {
				this.view = "detail";
				const fullHash = window.location.hash.substring(1);
				const hashArray = fullHash.split("&");

				this.currentPartner = hashArray[0].substring(2);
				this.currentDetailPartner =
					this.partnerdata.partners[hashArray[0].substring(2)];
				if (hashArray[1] !== undefined) {
					this.currentPartnerAddressID = hashArray[1].substring(2);
				}
				this.filterPartnerAdresses();
			} else {
				this.view = "map";
			}
		},
		openOverlay(params) {
			this.specialView = this.view;
			this.view = "detail";
			this.currentPartner = params.partnerId;
			this.currentPartnerAddressID = params.addressId;
			this.currentDetailPartner = this.partnerdata.partners[params.partnerId];
			this.filterPartnerAdresses();
		},
		goBack() {
			history.replaceState("", document.title, window.location.pathname);
			if (this.specialView) {
				this.view = this.specialView;
				this.specialView = false;
			} else {
				this.view = "map";
			}
		},
		filterMe(currentPartner) {
			return function (element) {
				return element.partnerID === currentPartner;
			};
		},
		filterPartnerAdresses() {
			let returnArray = [];
			if (this.currentPartner) {
				returnArray.push(
					this.partnerdata.addresses.filter(this.filterMe(this.currentPartner))
				);
			}
			this.currentDetailPartnerAddresses = returnArray;
		},
	},
};
</script>

<style>
</style>
