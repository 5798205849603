<template>
	<div class="mapView w-full">
		<div class="mapWrapper">
			<GMapMap
				:center="centerData"
				:zoom="zoomData"
				class="map"
				:options="options"
				ref="myMapRef"
				:disableDefaultUI="true"
			>
				<GMapCluster
					:minimumClusterSize="2"
					:styles="clusterStyle"
					:zoomOnClick="true"
					:maxZoom="18"
					:gridSize="5"
				>
					<GMapMarker
						:key="index"
						v-for="(m, index) in items"
						:position="m.position"
						:clickable="true"
						:draggable="false"
						:icon="getIconStyle(m.industries, m.is_auth)"
						@click="openMarker(m.id)"
					>
						<GMapInfoWindow
							:closeclick="true"
							@closeclick="openMarker(null)"
							:opened="openedMarkerID === m.id"
							:options="{
								maxWidth: 320,
								minWidth: 260,
							}"
						>
							<div @click="openMarker(null)" class="mapInfo">
								<div class="circles">
									<span
										v-for="(industry, index) in m.industries"
										:key="index"
										:class="industry"
										class="circle"
									></span>
								</div>
								<h3>{{ m.companyname }}</h3>
								<p>
									{{ m.name }}<br />{{ m.street }}<br />{{ m.zip }} {{ m.city }}
								</p>
								<a
									v-if="this.type !== 'help'"
									:href="'#p=' + m.partnerID + '&a=' + m.id"
									class="pt-2 inline-block"
									@click="triggerOverlay(m.partnerID, m.id)"
									>Details anzeigen</a
								>
							</div>
						</GMapInfoWindow>
					</GMapMarker>
				</GMapCluster>
			</GMapMap>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			openedMarkerID: null,
			countryData: {
				de: {
					center: { lat: 51.1642292, lng: 10.4541194 },
					zoom: 6,
				},
				at: {
					center: { lat: 47.516231, lng: 13.550072 },
					zoom: 7,
				},
				ch: {
					center: { lat: 46.818188, lng: 8.227512 },
					zoom: 7,
				},
			},
			options: {
				zoomControl: true,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				styles: [
					{
						featureType: "all",
						elementType: "labels.text.fill",
						stylers: [
							{
								saturation: 36,
							},
							{
								color: "#333333",
							},
							{
								lightness: 40,
							},
						],
					},
					{
						featureType: "all",
						elementType: "labels.text.stroke",
						stylers: [
							{
								visibility: "on",
							},
							{
								color: "#ffffff",
							},
							{
								lightness: 16,
							},
						],
					},
					{
						featureType: "all",
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#fefefe",
							},
							{
								lightness: 20,
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#fefefe",
							},
							{
								lightness: 17,
							},
							{
								weight: 1.2,
							},
						],
					},
					{
						featureType: "administrative.country",
						elementType: "geometry.stroke",
						stylers: [
							{
								visibility: "on",
							},
							{
								color: "#c1c1c1",
							},
						],
					},
					{
						featureType: "administrative.locality",
						elementType: "labels.text",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "administrative.locality",
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "landscape",
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5",
							},
							{
								lightness: 20,
							},
						],
					},
					{
						featureType: "poi",
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5",
							},
							{
								lightness: 21,
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [
							{
								color: "#dedede",
							},
							{
								lightness: 21,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 17,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 29,
							},
							{
								weight: 0.2,
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 18,
							},
						],
					},
					{
						featureType: "road.local",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								lightness: 16,
							},
						],
					},
					{
						featureType: "transit",
						elementType: "geometry",
						stylers: [
							{
								color: "#f2f2f2",
							},
							{
								lightness: 19,
							},
						],
					},
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#e9e9e9",
							},
							{
								lightness: 17,
							},
						],
					},
				],
			},
			type: null,
			mapImages: {
				"pin1-1": process.env.VUE_APP_FRONTEND_URL + "assets/images/pin1-1.png",
				"pin1-2": process.env.VUE_APP_FRONTEND_URL + "assets/images/pin1-2.png",
				"pin1-3": process.env.VUE_APP_FRONTEND_URL + "assets/images/pin1-3.png",
				"pin2-1": process.env.VUE_APP_FRONTEND_URL + "assets/images/pin2-1.png",
				"pin2-2": process.env.VUE_APP_FRONTEND_URL + "assets/images/pin2-2.png",
				"pin2-3": process.env.VUE_APP_FRONTEND_URL + "assets/images/pin2-3.png",
				pin3: process.env.VUE_APP_FRONTEND_URL + "assets/images/pin3.png",
				pin0: process.env.VUE_APP_FRONTEND_URL + "assets/images/pin0.png",
			},
			clusterStyle: [
				{
					width: 26,
					height: 26,
					className: "custom-clustericon custom-clustericon-1",
				},
				{
					width: 40,
					height: 40,
					className: "custom-clustericon custom-clustericon-2",
				},
				{
					width: 50,
					height: 50,
					className: "custom-clustericon custom-clustericon-3",
				},
			],
		};
	},
	props: {
		items: {
			type: Object,
		},
		countryView: {
			type: String,
			default: "de",
		},
	},
	computed: {
		centerData() {
			return this.countryData[this.countryView].center;
		},
		zoomData() {
			return this.countryData[this.countryView].zoom;
		},
	},
	mounted() {},
	methods: {
		openMarker(id) {
			this.openedMarkerID = id;
		},
		getIconStyle(industries, is_auth) {
			let pin = "",
				style = {};
			if (is_auth == true) {
				if (industries.length === 1) {
					if (industries[0] === "heavyconstruction") {
						pin = "pin1-3";
					}
					if (industries[0] === "survey") {
						pin = "pin1-2";
					}
					if (industries[0] === "building") {
						pin = "pin1-1";
					}
				} else if (industries.length === 2) {
					if (
						industries.includes("building") &&
						industries.includes("survey")
					) {
						pin = "pin2-1";
					}
					if (
						industries.includes("building") &&
						industries.includes("heavyconstruction")
					) {
						pin = "pin2-3";
					}
					if (
						industries.includes("survey") &&
						industries.includes("heavyconstruction")
					) {
						pin = "pin2-2";
					}
				} else {
					pin = "pin3";
				}
				style = {
					url: this.mapImages[pin],
					scaledSize: { width: 20, height: 30 },
					labelOrigin: { x: 0, y: 0 },
					anchor: { x: 10, y: 30 },
				};
			} else {
				style = {
					url: this.mapImages["pin0"],
					scaledSize: { width: 20, height: 30 },
					labelOrigin: { x: 0, y: 0 },
					anchor: { x: 10, y: 30 },
				};
			}
			return style;
		},
		triggerOverlay(partnerId, addressId) {
			this.$emit("open-overlay", {
				partnerId: partnerId,
				addressId: addressId,
			});
		},
	},
};
</script>

<style>
</style>