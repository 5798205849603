<template>
	<div>
		<header class="small flex">
			<div class="w-2/3">
				<div class="flex">
					<a href="#" class="text-red flex" @click.prevent="goBack()"
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="7.145"
							height="12.252"
							viewBox="0 0 7.145 12.252"
							class="mr-2 mt-0.5"
						>
							<path
								d="M7.182-8.646A.388.388,0,0,0,7.3-8.929a.388.388,0,0,0-.123-.282L1.461-14.932a.388.388,0,0,0-.282-.123.388.388,0,0,0-.282.123l-.614.614a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L5.107-8.929.282-4.1a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L.9-2.925a.388.388,0,0,0,.282.123.388.388,0,0,0,.282-.123Z"
								transform="translate(7.305 -2.802) rotate(180)"
								fill="#FF0033"
							/>
						</svg>
						Zurück</a
					>
				</div>
				<h2 class="pt-10">Ihr Partner</h2>
			</div>
			<div class="flex md:justify-end w-1/3 authContainer">
				<div
					v-for="(industry_auth, index) in partnerData.industries_auth"
					:key="index"
					:class="industry_auth"
				>
					<img
						v-if="industry_auth === 'building'"
						:src="this.getImage('assets/images/Hochbau.png')"
						class="big"
					/>
					<img
						v-if="industry_auth === 'survey'"
						:src="this.getImage('assets/images/Vermessung.png')"
						class="big"
					/>
					<img
						v-if="industry_auth === 'heavyconstruction'"
						:src="this.getImage('assets/images/Tiefbau.png')"
						class="big"
					/>
					<span :class="industry_auth" class="bigCircle"
						><svg
							xmlns="http://www.w3.org/2000/svg"
							width="15.569"
							height="11.933"
							viewBox="0 0 15.569 11.933"
						>
							<path
								d="M16.5-10.426a.93.93,0,0,1,.281.683.93.93,0,0,1-.281.683L9.231-1.788,7.865-.422a.93.93,0,0,1-.683.281A.93.93,0,0,1,6.5-.422L5.133-1.788,1.5-5.424a.93.93,0,0,1-.281-.683A.93.93,0,0,1,1.5-6.79L2.863-8.156a.93.93,0,0,1,.683-.281.93.93,0,0,1,.683.281L7.182-5.193l6.589-6.6a.93.93,0,0,1,.683-.281.93.93,0,0,1,.683.281Z"
								transform="translate(-1.215 12.074)"
								fill="#fff"
							/></svg
					></span>
				</div>
			</div>
		</header>
		<div class="w-full flex flex-col-reverse md:flex-row py-5">
			<div class="w-full md:w-2/3 md:mr-2.5 pr-5">
				<img :src="partnerData.logo" class="mb-16 max-w-xs" />
				<h2 class="companyName">{{ partnerData.companyname }}</h2>
				<div class="description" v-html="partnerData.description"></div>
				<a
					v-if="partnerData.mainwww != ''"
					:href="partnerData.mainwww"
					target="_blank"
					class="text-red font-bold pt-7 inline-block flex"
					><svg
						xmlns="http://www.w3.org/2000/svg"
						width="21.657"
						height="21.659"
						viewBox="0 0 21.657 21.659"
						class="mr-2 -mt-0.5"
					>
						<g
							id="Gruppe_477"
							data-name="Gruppe 477"
							transform="translate(0 0)"
						>
							<path
								id="Pfad_1301"
								data-name="Pfad 1301"
								d="M8.675,1.328A9.755,9.755,0,0,0,1.981,6.752H6.05a13.8,13.8,0,0,1,.9-2.835,8.679,8.679,0,0,1,1.724-2.59m4.306,0a8.631,8.631,0,0,1,1.724,2.59,13.8,13.8,0,0,1,.9,2.835h4.069a9.755,9.755,0,0,0-6.694-5.424M15.8,7.84a21.345,21.345,0,0,1,.2,2.989,21.318,21.318,0,0,1-.2,2.989h4.3a9.8,9.8,0,0,0,0-5.977Zm-.192,7.067a13.8,13.8,0,0,1-.9,2.835,8.7,8.7,0,0,1-1.724,2.59,9.755,9.755,0,0,0,6.694-5.424Zm-4.778,5.624A6.038,6.038,0,0,1,7.941,17.3a12.5,12.5,0,0,1-.777-2.388h7.33a12.5,12.5,0,0,1-.777,2.388,6.018,6.018,0,0,1-2.888,3.236M14.7,13.817a19.994,19.994,0,0,0,.215-2.989A20.019,20.019,0,0,0,14.7,7.84H6.959a20.019,20.019,0,0,0-.215,2.989,19.994,19.994,0,0,0,.215,2.989Zm-.2-7.065H7.163a12.443,12.443,0,0,1,.78-2.388,6.025,6.025,0,0,1,2.885-3.236,6.021,6.021,0,0,1,2.888,3.233,12.531,12.531,0,0,1,.777,2.39M8.675,20.332a8.744,8.744,0,0,1-1.724-2.59,13.849,13.849,0,0,1-.9-2.835H1.981a9.755,9.755,0,0,0,6.694,5.424M5.861,13.817a21.679,21.679,0,0,1,0-5.977H1.555a9.8,9.8,0,0,0,0,5.977Zm15.147.714L21,14.567A10.832,10.832,0,0,1,.661,14.561l-.005-.01v0l-.005-.013A10.832,10.832,0,0,1,21,7.1l0,.01,0,0,0,.013v0a10.88,10.88,0,0,1,0,7.408"
								transform="translate(0 -0.001)"
								fill="#333"
								fill-rule="evenodd"
							/>
						</g>
					</svg>
					{{ partnerData.mainwww }}</a
				>
				<div class="border-b border-my-red py-7">
					<div :key="index" v-for="(a, index) in currentAddress">
						<detail-address-item
							v-if="a.headquarter === 'false'"
							:addressData="a"
						></detail-address-item>
					</div>
				</div>
				<div class="py-7">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="29"
						height="30.169"
						viewBox="0 0 29 30.169"
					>
						<path
							d="M10.44,28.793h8.117V10.733a.066.066,0,0,0-.022-.054.077.077,0,0,0-.054-.022H10.516a.09.09,0,0,0-.054.022l-.01.01a.1.1,0,0,0-.013.045v18.06ZM.685,30.169a.688.688,0,0,1,0-1.376H3.453V18.411a1.44,1.44,0,0,1,.427-1.023v0a1.447,1.447,0,0,1,1.026-.424h.462V14.925a.689.689,0,0,1,.688-.688H9.067v-3.5a1.444,1.444,0,0,1,.379-.975l.045-.051a1.452,1.452,0,0,1,1.026-.424h.44V7.209a.689.689,0,0,1,.688-.688h.822V4.47a.687.687,0,0,1,.685-.688h.663V.685a.687.687,0,0,1,1.373,0v3.1h.659a.689.689,0,0,1,.688.688V6.521h.822a.687.687,0,0,1,.685.688V9.283h.44a1.452,1.452,0,0,1,1.453,1.45v9.85l.076,0h4.141a1.467,1.467,0,0,1,1.026.427A1.445,1.445,0,0,1,25.6,22.03v6.763h2.711a.688.688,0,0,1,0,1.376H.685ZM12.568,14.295a.688.688,0,0,1,0-1.376h3.861a.688.688,0,0,1,0,1.376Zm0,11.58a.687.687,0,0,1,0-1.373h3.861a.687.687,0,1,1,0,1.373Zm0-3.861a.687.687,0,0,1,0-1.373h3.861a.687.687,0,1,1,0,1.373Zm0-3.858a.688.688,0,0,1,0-1.376h3.861a.688.688,0,1,1,0,1.376ZM19.933,24.5h2.373a.687.687,0,1,1,0,1.373H19.933v2.918h4.294V22.03a.075.075,0,0,0-.022-.051v0a.077.077,0,0,0-.054-.022H20.01a.077.077,0,0,0-.054.022h0a.075.075,0,0,0-.019.054ZM6.387,25.875a.687.687,0,0,1,0-1.373H9.067V22.014H6.387a.687.687,0,0,1,0-1.373H9.067V18.407a.075.075,0,0,0-.022-.051.077.077,0,0,0-.054-.022H4.906a.077.077,0,0,0-.054.022h0a.075.075,0,0,0-.019.054V28.793H9.067V25.875ZM9.067,15.613H6.741v1.348H8.99l.076,0ZM15.161,5.158H13.842V6.524h1.319ZM16.668,7.9H12.329V9.283h4.339Z"
							fill="#333"
							fill-rule="evenodd"
						/>
					</svg>

					<div :key="index" v-for="(a, index) in headquarter" class="mt-1">
						<detail-address-item :addressData="a"></detail-address-item>
					</div>
				</div>
			</div>
			<div class="w-full md:w-1/3 md:ml-2.5">
				<div class="industriesList">
					<ul class="checkList" id="industries">
						<li
							v-for="(industry, index) in partnerData.industries"
							:key="index"
							class="w-1/2 md:w-full"
						>
							<span
								class="active"
								:id="index"
								:class="globals['industries'][industry]['class']"
								>{{ globals["industries"][industry]["value"] }}</span
							>
						</li>
					</ul>
				</div>
				<h3><strong>Lösungen</strong></h3>
				<div class="solutionList">
					<span
						v-for="(solution, index) in partnerData.solutions"
						:key="index"
						class="solutionListItem w-1/2 md:w-full"
						v-html="globals['solutions'][solution]"
					></span>
				</div>
			</div>
		</div>
		<div
			class="w-full flex my-5"
			v-if="
				detailPartnerAddresses[0] !== undefined &&
				detailPartnerAddresses[0].length > 2
			"
		>
			<div class="w-full">
				<a
					href="#"
					class="moreAddresses"
					@click.prevent="this.showAddresses = !this.showAddresses"
					:class="this.showAddresses ? '' : 'closed'"
				>
					Weitere Niederlassungen
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="12.252"
						height="7.145"
						viewBox="0 0 12.252 7.145"
					>
						<path
							d="M7.182-8.646A.388.388,0,0,0,7.3-8.929a.388.388,0,0,0-.123-.282L1.461-14.932a.388.388,0,0,0-.282-.123.388.388,0,0,0-.282.123l-.614.614a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L5.107-8.929.282-4.1a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L.9-2.925a.388.388,0,0,0,.282.123.388.388,0,0,0,.282-.123Z"
							transform="translate(-2.802 -0.16) rotate(90)"
							fill="#333"
						/>
					</svg>
				</a>
				<div v-if="showAddresses">
					<div :key="index" v-for="(a, index) in detailPartnerAddresses[0]">
						<div
							class="border-b border-gray-light pl-2.5"
							v-if="
								a.headquarter !== 'true' &&
								a.id !== this.currentPartnerAddressID
							"
						>
							<detail-address-item
								class="w-2/3 pr-5 py-3"
								:addressData="a"
							></detail-address-item>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="w-full flex">
			<a href="#" class="btn withIcon" @click.prevent="goBack()"
				><svg
					xmlns="http://www.w3.org/2000/svg"
					width="7.145"
					height="12.252"
					viewBox="0 0 7.145 12.252"
				>
					<path
						d="M7.182-8.646A.388.388,0,0,0,7.3-8.929a.388.388,0,0,0-.123-.282L1.461-14.932a.388.388,0,0,0-.282-.123.388.388,0,0,0-.282.123l-.614.614a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L5.107-8.929.282-4.1a.388.388,0,0,0-.123.282.388.388,0,0,0,.123.282L.9-2.925a.388.388,0,0,0,.282.123.388.388,0,0,0,.282-.123Z"
						transform="translate(7.305 -2.802) rotate(180)"
						fill="#fff"
					/>
				</svg>
				Zurück</a
			>
		</div>
	</div>
</template>

<script>
import detailAddressItem from "./detailAddressItem.vue";

export default {
	components: {
		detailAddressItem,
	},
	data() {
		return {
			showAddresses: true,
			frontendBase: process.env.VUE_APP_FRONTEND_URL,
		};
	},
	props: {
		partnerData: Object,
		detailPartnerAddresses: Object,
		globals: Object,
		currentPartnerAddressID: String,
	},
	computed: {
		headquarter: function () {
			let returner;
			if (this.detailPartnerAddresses[0] !== undefined) {
				returner = this.detailPartnerAddresses[0].filter(function (u) {
					if (u.headquarter === "true") {
						return u;
					}
				});
			}
			return returner;
		},
		currentAddress: function () {
			let returner;
			if (this.detailPartnerAddresses[0] !== undefined) {
				returner = this.detailPartnerAddresses[0].filter(
					this.filterMe(this.currentPartnerAddressID)
				);
			}
			return returner;
		},
	},
	methods: {
		getImage(imageName) {
			return this.frontendBase + imageName;
		},
		goBack() {
			this.$emit("go-back");
		},
		filterMe(currentAddressID) {
			return function (element) {
				return element.id === currentAddressID;
			};
		},
	},
};
</script>

<style>
</style>