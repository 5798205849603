<template>
	<div class="listView">
		<div class="zipList">
			<span>PLZ</span>
			<ul>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTabAll' ? 'active' : ''"
						id="zipTabAll"
						@click.prevent="currentTab = $event.target.id"
						>ALLE</a
					>
				</li>
				<li v-if="countryView === 'de'">
					<a
						href="#"
						:class="currentTab === 'zipTab0' ? 'active' : ''"
						id="zipTab0"
						@click.prevent="currentTab = $event.target.id"
						>0</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab1' ? 'active' : ''"
						id="zipTab1"
						@click.prevent="currentTab = $event.target.id"
						>1</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab2' ? 'active' : ''"
						id="zipTab2"
						@click.prevent="currentTab = $event.target.id"
						>2</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab3' ? 'active' : ''"
						id="zipTab3"
						@click.prevent="currentTab = $event.target.id"
						>3</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab4' ? 'active' : ''"
						id="zipTab4"
						@click.prevent="currentTab = $event.target.id"
						>4</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab5' ? 'active' : ''"
						id="zipTab5"
						@click.prevent="currentTab = $event.target.id"
						>5</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab6' ? 'active' : ''"
						id="zipTab6"
						@click.prevent="currentTab = $event.target.id"
						>6</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab7' ? 'active' : ''"
						id="zipTab7"
						@click.prevent="currentTab = $event.target.id"
						>7</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab8' ? 'active' : ''"
						id="zipTab8"
						@click.prevent="currentTab = $event.target.id"
						>8</a
					>
				</li>
				<li>
					<a
						href="#"
						:class="currentTab === 'zipTab9' ? 'active' : ''"
						id="zipTab9"
						@click.prevent="currentTab = $event.target.id"
						>9</a
					>
				</li>
			</ul>
		</div>
		<div class="zipTabs">
			<div class="zipTabAll" v-if="currentTab === 'zipTabAll'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in items"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab0" v-if="currentTab === 'zipTab0'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses0"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab1" v-if="currentTab === 'zipTab1'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses1"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab2" v-if="currentTab === 'zipTab2'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses2"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab3" v-if="currentTab === 'zipTab3'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses3"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab4" v-if="currentTab === 'zipTab4'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses4"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab5" v-if="currentTab === 'zipTab5'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses5"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab6" v-if="currentTab === 'zipTab6'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses6"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab7" v-if="currentTab === 'zipTab7'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses7"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab8" v-if="currentTab === 'zipTab8'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses8"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
			<div class="zipTab9" v-if="currentTab === 'zipTab9'">
				<div class="addressList">
					<div
						class="address"
						:class="a.solutions.includes('werkstaetten') ? 'werkstatt' : ''"
						:key="index"
						v-for="(a, index) in addresses9"
					>
						<addressListItem
							:a="a"
							:globals="globals"
							@open-overlay="openOverlay"
						></addressListItem>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import addressListItem from "./addressListItem.vue";
export default {
	components: {
		addressListItem,
	},
	props: {
		items: Object,
		globals: Object,
		countryView: {
			type: String,
			default: "de",
		},
	},
	data() {
		return {
			currentTab: "zipTabAll",
		};
	},
	computed: {
		addresses0: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "0") {
					return u;
				}
			});
		},
		addresses1: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "1") {
					return u;
				}
			});
		},
		addresses2: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "2") {
					return u;
				}
			});
		},
		addresses3: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "3") {
					return u;
				}
			});
		},
		addresses4: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "4") {
					return u;
				}
			});
		},
		addresses5: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "5") {
					return u;
				}
			});
		},
		addresses6: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "6") {
					return u;
				}
			});
		},
		addresses7: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "7") {
					return u;
				}
			});
		},
		addresses8: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "8") {
					return u;
				}
			});
		},
		addresses9: function () {
			return this.items.filter(function (u) {
				if (u.zip.charAt(0) === "9") {
					return u;
				}
			});
		},
	},
	methods: {
		openOverlay(params) {
			this.$emit("open-overlay", params);
		},
	},
};
</script>

<style>
</style>