import { createApp } from "vue";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import App from "./App.vue";
import "./index.css";

const app = createApp(App);
app
	.use(VueGoogleMaps, {
		load: {
			key: "AIzaSyDIf2d_BXaxh8Okl-B6qE-juvx9Vfyd9Is",
		},
	})
	.mount("#app");
